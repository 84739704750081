import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import {serverLink} from "../../resources/url";
import ReportTable from "../common/table/report_table";
import Loader from "../common/loader/loader";
import { setgeneralDetails} from "../../actions/actions";
import DataLoader from "../common/dataLoader/dataLoader";

function FarmersListNotMapped(props) {
    const [IsLoading, setIsLoading] = useState(true)

    const columns = ["S/N", "Farmer Name", "Gender", "Phone", "State", "LGA",  "Crop Grown", "Captured By", "View"];
    const [data, setdata] = useState([])

    useEffect( () => {
        getFarmersListUnMapped();
    }, [""]);

    const getFarmersListUnMapped = async () => {
        await axios.get(`${serverLink}farmer/list/unmapped`)
            .then((result) => {
                if (result.data.length > 0) {
                    let rows = [];
                    result.data.map((x, i) => {
                        rows.push([
                            i + 1,
                            x.farmer_fname +" "+ x.farmer_mname +" "+ x.farmer_sname,
                            x.farmer_gender,
                            x.farmer_phone,
                            x.farmer_state,
                            x.farmer_lga,
                            x.crop,
                            x.agent,
                            <Link to="/farmer-details" className={"btn btn-info btn-sm "} onClick={()=>{
                                props.setOnGeneralDetails(x);
                            }} >
                                View
                            </Link>
                        ])
                    })
                    setdata(rows)
                }
                setIsLoading(false)
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    }

    return (
        IsLoading ?
            <div className="container-xl" style={{height: '700px'}}>
                <div className="page-header d-print-none">
                    <DataLoader/>
                </div>
            </div>
            :
            <>
                <div className="container-xl">
                    <div className="page-header d-print-none">
                        <div className="row align-items-center">
                            <div className="col">
                                <div className="page-pretitle">
                                    {/*Agent*/}
                                </div>
                                <h2 className="page-title">
                                    Farmers List Not Mapped
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-body">
                    <div className="container-xl">
                        <div className="row row-deck row-cards">
                            <div className="col-lg-12">
                                <div className="row row-cards">
                                    <div className="col-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <h3 className="card-title">Farmer(s) Report Not Mapped</h3>
                                                <div className="table-responsive">
                                                    <ReportTable data={data} columns={columns} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </>
    );
}
const mapStateToProps = (state) => {
    return {
        loginData: state.loginDetails,
        farmersRecord: state.farmersData,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setOnGeneralDetails: (p) => {
            dispatch(setgeneralDetails(p));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FarmersListNotMapped);
