import React, { useEffect} from "react";
import {connect} from "react-redux";
import {
    setAgentData,
    setFarmersData,
    setgeneralDetails,
    setLoginDetails,
} from "../../actions/actions";


function Logout(props) {

    const onLogout = async () => {
        window.sessionStorage.setItem("isLoggedIn", false);
        window.sessionStorage.clear();
        props.setOnLoginDetails([])
        props.setOnFarmerDetails([])
        props.setOnAgentDetails([])
        props.setOnGeneralDetails("")
        window.location.href = '/';
    }

    useEffect(()=>{
        onLogout();
    }, [""])


}

const mapStateToProps = (state) => {
    return {
        loginData: state.loginDetails,
    };
};

const mapDisptachToProps = (dispatch) => {
    return {
        setOnLoginDetails: (p) => {
            dispatch(setLoginDetails(p));
        },
        setOnFarmerDetails: (p) => {
            dispatch(setFarmersData(p));
        },
        setOnAgentDetails: (p) => {
            dispatch(setAgentData(p));
        },
        setOnGeneralDetails: (p) => {
            dispatch(setgeneralDetails(p));
        },
    };
};


export default connect(mapStateToProps, mapDisptachToProps)(Logout);
