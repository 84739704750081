import React, {useState, useEffect} from "react";
import {connect} from "react-redux";
import axios from "axios";
import {serverLink} from "../../resources/url";
import Chart from "react-google-charts";
import StateData from "../../resources/state_and_lga.json";
import ApexChart from "react-apexcharts";
import {setAgentData, setFarmersData} from "../../actions/actions";
import Skeleton from "../common/skeleton/skeleton";

function Dashboard(props) {

    // const [IsLoading, setIsLoading] = useState(!(!(props.loginData.length > 0 && props.farmersRecord.length > 0 && props.agentRecord.length > 0 )))
    const [IsLoading, setIsLoading] = useState(true)
    const [cropType, setCropType] = useState([])
    const [genderType, setGenderType] = useState([])
    const [qualificationType, setQualificationType] = useState([]);

    const [apexChartOption, setApexChartOption] = useState({});
    const [apexSeries, setApexSeries] = useState([]);
    const [apexChartOption2, setApexChartOption2] = useState({});
    const [apexSeries2, setApexSeries2] = useState([]);
    const [apexChartOption3, setApexChartOption3] = useState({});
    const [apexSeries3, setApexSeries3] = useState([]);

    const [farmerCount, setFarmerCount] = useState(0);
    const [farmersMapped, setFarmersMapped] = useState(0);
    const [farmersNotMapped, setFarmersNotMapped] = useState(0);
    const [agentCount, setAgentCount] = useState(0);
    const [squareMetersCount, setSquareMeters] = useState(0);
    const [hectaresCount, setHectares] = useState(0);

    useEffect(() => {
        getFarmersAnalyticsData();
    }, [""]);

    const getFarmersAnalyticsData = async () => {
        await axios.get(`${serverLink}dashboard/data`)
            .then((result) => {
                if (result.data.farmerData.length > 0) {

                    let farmersData = result.data.farmerData;
                    let farmData = result.data.farmData;
                    let agentsData = result.data.agentData;
                    props.setOnFarmersData(farmersData)
                    props.setOnAgentData(agentsData)

                    let qualification = ['Primary', 'Secondary', 'Tertiary Institution', 'Never Attend School' ];
                    let gender = ['Male', 'Female'];
                    let cropsList = [
                        'Rice',
                        'Maize',
                        'Beans',
                        'Wheat',
                        'Cotton',
                        'Sorghum/Millet',
                        'Soybeans',
                        'Groundnuts',
                        'Tomatoes',
                        'Sesame',
                        'Cashew',
                        'Sugarcane',
                        'Cassava',
                        'Potatoes',
                        'Ginger',
                        'Castor',
                        'Yam',
                        'Cocoa',
                        'Palm trees',
                        'Timber',
                        'Onion',
                        'Herbiscus',
                        'Kenaf',
                        'Bee',
                        'Sheep and goats',
                        'Poultry',
                        'Fisheries and aquatic',
                        'Cattle',
                        'Moringa',
                        'Mangoes',
                        'Piggry',
                        'Uggu',
                        'Rubber',
                        'Watermelon',
                        'Processor'
                    ];
                    let result1 = [];
                    let result2 = [];
                    let result3 = [];
                    let cropTypes = [];
                    let cropCount = [];
                    let stateTypes = [];
                    let farmersCount = [];
                    let state_result = [];
                    let square_meters = 0;
                    let hectares = 0;

                    StateData.map((e, i) => {
                        let count = farmersData.filter(item => item.farmer_state.toString() === e.state.toString()).length
                        farmersCount.push(count)
                        stateTypes.push(e.state)
                        state_result.push([e.state, count]);
                    })


                    qualification.map((e, i) => {
                        let count = farmersData.filter(item => item.highest_qualification === e).length
                        result1.push([e, count]);
                    })

                    gender.map((e, i) => {
                        let count = farmersData.filter(item => item.farmer_gender === e).length
                        result2.push([e, count]);
                    })

                    cropsList.map((e, i) => {
                        let count = farmersData.filter(item => item.crop === e).length
                        cropCount.push(count)
                        cropTypes.push(e)
                        result3.push([e, count]);
                    })

                    farmData.map((item, index)=> {
                        square_meters +=+ item.farm_size;
                        hectares +=+ item.farm_size_hecters;
                    })
                    setSquareMeters(square_meters)
                    setHectares(hectares)

                    setApexChartOption({
                        ...apexChartOption,
                        chart: {
                            id: "basic-bar"
                        },
                        xaxis: {
                            categories: cropTypes
                        }
                    })
                    setApexSeries([{
                        name: "Count",
                        data: cropCount
                    }])

                    setApexChartOption2({
                        ...apexChartOption,
                        chart: {
                            id: "basic-line"
                        },
                        xaxis: {
                            categories: ["Mapped", "Not Mapped"]
                        }
                    })
                    setApexSeries2([{
                        name: "Count",
                        data: [farmersData.filter(e=>e.farm_statuss.toString() === "1").length, farmersData.filter(e=>e.farm_statuss.toString() === "0").length]
                    }])

                    setApexChartOption3({
                        ...apexChartOption3,
                        chart: {
                            id: "basic-bar"
                        },
                        xaxis: {
                            categories: stateTypes
                        }
                    })
                    setApexSeries3([{
                        name: "Count",
                        data: farmersCount
                    }])

                    setQualificationType(result1)
                    setGenderType(result2)
                    setCropType(result3)

                    setFarmerCount(farmersData.length)
                    setFarmersMapped(farmersData.filter(e=>e.farm_statuss.toString() === "1").length)
                    setFarmersNotMapped(farmersData.filter(e=>e.farm_statuss.toString() === "0").length)
                    setAgentCount(agentsData.length)
                }
                setIsLoading(false)
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    }

    const data = [["Qualification", ""], ...qualificationType];
    const options = {
        chart: {
            title: " Farmers Highest Qualifications Trend",
            subtitle: "Count",
            width: '100%',
        },
    };


    const data2 = [["Gender", "Count"], ...genderType];
    const options2 = {
        title: "Farmers Count By Gender",
        is3D: true,
    };


    const data3 = [["Count", "Crop Grown"], ...cropType];
    const options3 = {
        title: "Crop Grown By Farmers Trend ",
        curveType: "function",
        legend: {position: "bottom"},
        width: '100%',
    };


    return (
        IsLoading ?
            // <div className="container-xl" style={{height: '700px'}}>
            //     <div className="page-header d-print-none">
            //         <DataLoader/>
            //     </div>
            // </div>
            <Skeleton/>
            :
        <>
            <div className="container-xl">
                <div className="page-header d-print-none">
                    <div className="row align-items-center">
                        <div className="col">
                            <div className="page-pretitle">
                                Overview
                            </div>
                            <h2 className="page-title">
                                Dashboard
                            </h2>
                        </div>
                    </div>
                </div>
            </div>
            <div className="page-body">
                <div className="container-xl">
                    <div className="row row-deck row-cards">
                        <div className="col-sm-6 col-lg-3">
                            <div className="card">
                                <div className="card-body">
                                    <div className="d-flex align-items-center">
                                        <div className="subheader">Farmers</div>
                                        <div className="ms-auto lh-1">
                                        </div>
                                    </div>
                                    <div className="h1 mb-3">{farmerCount}</div>
                                    <div className="d-flex mb-2">
                                        <div>Total Registered Farmers</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-3">
                            <div className="card">
                                <div className="card-body">
                                    <div className="d-flex align-items-center">
                                        <div className="subheader">Farmers</div>

                                    </div>
                                    <div className="h1  mb-3">{farmersMapped}</div>
                                    <div>Farm Land Mapping Captured</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-3">
                            <div className="card">
                                <div className="card-body">
                                    <div className="d-flex align-items-center">
                                        <div className="subheader">Farmers </div>

                                    </div>
                                    <div className="h1  mb-3">{farmersNotMapped}</div>
                                    <div>Farm Land Mapping Not Captured</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-3">
                            <div className="card">
                                <div className="card-body">
                                    <div className="d-flex align-items-center">
                                        <div className="subheader">Agent</div>
                                    </div>
                                    <div className="h1  mb-3">{agentCount}</div>
                                    <div>Total Registered Agent</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="row row-cards">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            {
                                                qualificationType.length > 0 &&
                                                <Chart
                                                    chartType="Bar"
                                                    width="100%"
                                                    height="400px"
                                                    data={data}
                                                    options={options}
                                                />
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="card">
                                {
                                    genderType.length > 0 &&
                                    <Chart
                                        chartType="PieChart"
                                        width="100%"
                                        height="400px"
                                        data={data2}
                                        options={options2}
                                    />
                                }
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="row row-cards">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            {
                                                cropType.length > 0 &&
                                                <Chart
                                                    chartType="LineChart"
                                                    data={data3}
                                                    options={options3}
                                                    width={"100%"}
                                                    height={"400px"}
                                                />
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="row row-cards">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-header">Crop Grown By Farmers Trend</div>
                                        <div className="card-body">
                                            {

                                                <ApexChart
                                                    options={apexChartOption}
                                                    series={apexSeries}
                                                    type="bar"
                                                    width="100%"
                                                />
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="row row-cards">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <h4>Farmers Registration Trend By State Of Origin</h4>
                                            {

                                                <ApexChart
                                                    options={apexChartOption3}
                                                    series={apexSeries3}
                                                    type="bar"
                                                    width="100%"
                                                />
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="row row-cards">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-header">Farm Mapping Trend</div>
                                        <div className="card-body">
                                            {

                                                <ApexChart
                                                    options={apexChartOption2}
                                                    series={apexSeries2}
                                                    type="line"
                                                    width="100%"
                                                />
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-6">
                            <div className="card">
                                <div className="card-body">
                                    <div className="d-flex align-items-center">
                                        <div className="subheader">Farm Size In Square Meters </div>

                                    </div>
                                    <div className="h1  mb-3">{squareMetersCount.toFixed(3)} (m2)</div>
                                    <div>Total Farm Size In Square Meters</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-6">
                            <div className="card">
                                <div className="card-body">
                                    <div className="d-flex align-items-center">
                                        <div className="subheader">Farm Size In Hectares </div>

                                    </div>
                                    <div className="h1  mb-3">{hectaresCount.toFixed(3)} (ha)</div>
                                    <div>Total Farm Size In Hectares</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        loginData: state.loginDetails,
        farmersRecord: state.farmersData,
        agentRecord: state.agentData,
    };
};

const mapDisptachToProps = (dispatch) => {
    return {
        setOnFarmersData: (p) => {
            dispatch(setFarmersData(p));
        },
        setOnAgentData: (p) => {
            dispatch(setAgentData(p));
        },
    };
};

export default connect(mapStateToProps, mapDisptachToProps)(Dashboard);
