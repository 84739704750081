import React from "react";
import { Route, Routes } from "react-router-dom";
import Login from "../authentication/login";
import PageNotFound from "../404/page-not-found";
import Logout from "../authentication/logout";

export default function PublicRoutes() {
    return (
        <Routes>
            <Route exact path="/" element={<Login/>} />
            <Route path="/login" element={<Login />} />
            <Route path="/logout" element={<Logout/>} />
            <Route path="*" element={<PageNotFound />} />
        </Routes>
    );
}