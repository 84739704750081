import React, {useState} from "react";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {serverLink} from "../../resources/url";
import Loader from "../common/loader/loader";
import {formatDateAndTime} from "../../resources/constants";

function FarmerDetails(props) {

    const [IsLoading, setIsLoading] = useState(false)
    const [farmer, setFarmer] = useState(props.farmerDetails)
    return (
        IsLoading ?
            <Loader/>
            :
            <>
                <div className="container-xl">
                    <div className="page-header d-print-none">
                        <div className="row align-items-center">
                            <div className="col">
                                <div className="page-pretitle">
                                    {/*Overview*/}
                                </div>
                                <h2 className="page-title">
                                    Farmer Details
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-body">
                    <div className="container-xl">
                        <div className="row row-deck row-cards">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row g-2 align-items-center">
                                            <div className="col-auto">
                                                <span className="avatar avatar-lg"
                                                      style={{backgroundImage: `url(${serverLink}public/uploads/passport/${farmer.farmer_pic})`}}></span>
                                            </div>
                                            <div className="col">
                                                <h4 className="card-title m-0">
                                                    <a href="#">{farmer.farmer_fname} {farmer.farmer_mname} {farmer.farmer_sname}</a>
                                                </h4>
                                                <div className="text-muted">
                                                    Captured By: {farmer.agent}
                                                </div>
                                                <div className="small mt-1">
                                                    <span className="badge bg-green"></span> Active
                                                </div>
                                            </div>
                                            <div className="col-auto">
                                                <a href="#" className="badge bg-primary">{farmer.farm_statuss.toString() === "1" ? "Mapped" : "Not Mapped"} </a>
                                            </div>

                                        </div>
                                        <div className="row mt-4">
                                            <div className="col-md-6 col-xl-3 mt-3">
                                                <div className="card card-sm">
                                                    <div className="card-body">
                                                        <div className="row align-items-center">
                                                            <div className="col-auto">

                                                            </div>
                                                            <div className="col">
                                                                <div className="fw-bold">
                                                                    Gender
                                                                </div>
                                                                <div className="text-muted">
                                                                    {farmer.farmer_gender}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-xl-3 mt-3">
                                                <div className="card card-sm">
                                                    <div className="card-body">
                                                        <div className="row align-items-center">
                                                            <div className="col-auto">
                                                            </div>
                                                            <div className="col">
                                                                <div className="fw-bold">
                                                                    Date Of Birth
                                                                </div>
                                                                <div className="text-muted">
                                                                    {formatDateAndTime(farmer.farmer_dob, 'date')}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-xl-3 mt-3">
                                                <div className="card card-sm">
                                                    <div className="card-body">
                                                        <div className="row align-items-center">
                                                            <div className="col-auto">
                                                            </div>
                                                            <div className="col">
                                                                <div className="fw-bold">
                                                                    Marital Status
                                                                </div>
                                                                <div className="text-muted">
                                                                    {farmer.marital}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-xl-3 mt-3">
                                                <div className="card card-sm">
                                                    <div className="card-body">
                                                        <div className="row align-items-center">
                                                            <div className="col-auto">
                                                            </div>
                                                            <div className="col">
                                                                <div className="fw-bold">
                                                                    Highest Qualification
                                                                </div>
                                                                <div className="text-muted">
                                                                    {farmer.highest_qualification}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-md-6 col-xl-3 mt-3">
                                                <div className="card card-sm">
                                                    <div className="card-body">
                                                        <div className="row align-items-center">
                                                            <div className="col-auto">

                                                            </div>
                                                            <div className="col">
                                                                <div className="fw-bold">
                                                                    Phone Number
                                                                </div>
                                                                <div className="text-muted">
                                                                    {farmer.farmer_phone}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-xl-3 mt-3">
                                                <div className="card card-sm">
                                                    <div className="card-body">
                                                        <div className="row align-items-center">
                                                            <div className="col-auto">
                                                            </div>
                                                            <div className="col">
                                                                <div className="fw-bold">
                                                                    Email Address
                                                                </div>
                                                                <div className="text-muted text-lowercase">
                                                                    {farmer.farmer_email}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-xl-3 mt-3">
                                                <div className="card card-sm">
                                                    <div className="card-body">
                                                        <div className="row align-items-center">
                                                            <div className="col-auto">
                                                            </div>
                                                            <div className="col">
                                                                <div className="fw-bold">
                                                                    State
                                                                </div>
                                                                <div className="text-muted">
                                                                    {farmer.farmer_state}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-xl-3 mt-3">
                                                <div className="card card-sm">
                                                    <div className="card-body">
                                                        <div className="row align-items-center">
                                                            <div className="col-auto">
                                                            </div>
                                                            <div className="col">
                                                                <div className="fw-bold">
                                                                    LGA
                                                                </div>
                                                                <div className="text-muted">
                                                                    {farmer.farmer_lga}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            farmer.farm_statuss.toString() === "1" ?
                                                <>
                                                    <div className="row mt-3">
                                                        <div className="hr-text hr-text-left">Farm Details</div>
                                                        <div className="col-md-6 col-xl-3">
                                                            <div className="card card-sm">
                                                                <div className="card-body">
                                                                    <div className="row align-items-center">
                                                                        <div className="col-auto">

                                                                        </div>
                                                                        <div className="col">
                                                                            <div className="fw-bold">
                                                                                Crop Grown
                                                                            </div>
                                                                            <div className="text-muted">
                                                                                {farmer.crop}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6 col-xl-3">
                                                            <div className="card card-sm">
                                                                <div className="card-body">
                                                                    <div className="row align-items-center">
                                                                        <div className="col-auto">
                                                                        </div>
                                                                        <div className="col">
                                                                            <div className="fw-bold">
                                                                                Farm Size In Square Meters
                                                                            </div>
                                                                            <div className="text-muted text-lowercase">
                                                                                {farmer.farm_size} (m2)
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6 col-xl-3">
                                                            <div className="card card-sm">
                                                                <div className="card-body">
                                                                    <div className="row align-items-center">
                                                                        <div className="col-auto">
                                                                        </div>
                                                                        <div className="col">
                                                                            <div className="fw-bold">
                                                                                Farm Size In Hectares
                                                                            </div>
                                                                            <div className="text-muted">
                                                                                {farmer.farm_size_hecters}  (ha)
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6 col-xl-3">
                                                            <div className="card card-sm">
                                                                <div className="card-body">
                                                                    <div className="row align-items-center">
                                                                        <div className="col-auto">
                                                                        </div>
                                                                        <div className="col">
                                                                            <div className="fw-bold">
                                                                                View Farm Location On Google Map
                                                                            </div>
                                                                            <div className="text-muted mt-1">
                                                                                <a href="/farm-preview"
                                                                                      className="btn btn-primary btn-sm">
                                                                       <span>
                                                                           <svg xmlns="http://www.w3.org/2000/svg"
                                                                                className="icon" width="24" height="24"
                                                                                viewBox="0 0 24 24" stroke-width="2"
                                                                                stroke="currentColor" fill="none"
                                                                                stroke-linecap="round"
                                                                                stroke-linejoin="round"><path
                                                                               stroke="none" d="M0 0h24v24H0z"
                                                                               fill="none"/><path
                                                                               d="M21 3l-6.5 18a0.55 .55 0 0 1 -1 0l-3.5 -7l-7 -3.5a0.55 .55 0 0 1 0 -1l18 -6.5"/></svg>
                                                                       </span> Farm Land Preview
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-3">
                                                        <div className="col-md-6 col-xl-3 mt-3">
                                                            <div className="card card-sm">
                                                                <div className="card-body">
                                                                    <div className="row align-items-center">
                                                                        <div className="col-auto">
                                                                        </div>
                                                                        <div className="col">
                                                                            <div className="fw-bold">
                                                                                State
                                                                            </div>
                                                                            <div className="text-muted">
                                                                                {farmer.farm_state}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6 col-xl-3 mt-3">
                                                            <div className="card card-sm">
                                                                <div className="card-body">
                                                                    <div className="row align-items-center">
                                                                        <div className="col-auto">
                                                                        </div>
                                                                        <div className="col">
                                                                            <div className="fw-bold">
                                                                                LGA
                                                                            </div>
                                                                            <div className="text-muted">
                                                                                {farmer.farm_lga}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6 col-xl-6 mt-3">
                                                            <div className="card card-sm">
                                                                <div className="card-body">
                                                                    <div className="row align-items-center">
                                                                        <div className="col-auto">
                                                                        </div>
                                                                        <div className="col">
                                                                            <div className="fw-bold">
                                                                                Address
                                                                            </div>
                                                                            <div className="text-muted">
                                                                                {farmer.farm_address}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                                :
                                                <>
                                                    <div className="hr-text hr-text-left">Farm Details</div>
                                                    <div
                                                        className="alert alert-important alert-warning alert-dismissible"
                                                        role="alert">
                                                        <div className="d-flex">
                                                            <div>
                                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                                     className="icon alert-icon" width="24" height="24"
                                                                     viewBox="0 0 24 24" stroke-width="2"
                                                                     stroke="currentColor" fill="none"
                                                                     stroke-linecap="round" stroke-linejoin="round">
                                                                    <path stroke="none" d="M0 0h24v24H0z"
                                                                          fill="none"></path>
                                                                    <path d="M12 9v2m0 4v.01"></path>
                                                                    <path
                                                                        d="M5 19h14a2 2 0 0 0 1.84 -2.75l-7.1 -12.25a2 2 0 0 0 -3.5 0l-7.1 12.25a2 2 0 0 0 1.75 2.75"></path>
                                                                </svg>
                                                            </div>
                                                            <div>
                                                                Sorry! farm land not mapped.
                                                            </div>
                                                        </div>
                                                        <a className="btn-close btn-close-white" data-bs-dismiss="alert"
                                                           aria-label="close"></a>
                                                    </div>
                                                </>
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
    );
}

const mapStateToProps = (state) => {
    return {
        loginData: state.loginDetails,
        farmerDetails: state.generalDetails,
    };
};
export default connect(mapStateToProps, null)(FarmerDetails);
