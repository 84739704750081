import React, {useState, useEffect} from "react";
import {Link} from "react-router-dom";
import {connect} from "react-redux";

import axios from "axios";
import {serverLink} from "../../../resources/url";
import {projectLogo} from "../../../resources/constants";

function Header(props) {

    const dismissClick = () => {
        document.getElementById("toggle-nav").click();
    }

    return (
        <>
            <header className="navbar navbar-expand-md navbar-light d-print-none">
                <div className="container-xl">
                    <button className="navbar-toggler"  id="toggle-nav" type="button" data-bs-toggle="collapse"
                            data-bs-target="#navbar-menu">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <h1 className="navbar-brand navbar-brand-autodark d-none-navbar-horizontal pe-0 pe-md-3">
                        <a href=".">
                            <img src="./static/logo.png" width="150" height="150" alt="Farmer Enumeration"
                                 className="navbar-brand-image"/>

                        </a>  <span style={{marginLeft: '10px'}}> Farmers Enumeration</span>
                    </h1>
                    <div className="navbar-nav flex-row order-md-last">
                        <div className="nav-item d-none d-md-flex me-3"></div>
                        <a href="?theme=dark" className="nav-link px-0 hide-theme-dark" title="Enable dark mode"
                           data-bs-toggle="tooltip" data-bs-placement="bottom">
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24"
                                 viewBox="0 0 24 24"
                                 stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round"
                                 stroke-linejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                <path
                                    d="M12 3c.132 0 .263 0 .393 0a7.5 7.5 0 0 0 7.92 12.446a9 9 0 1 1 -8.313 -12.454z"/>
                            </svg>
                        </a>
                        <a href="?theme=light" className="nav-link px-0 hide-theme-light" title="Enable light mode"
                           data-bs-toggle="tooltip" data-bs-placement="bottom">
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24"
                                 viewBox="0 0 24 24"
                                 stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round"
                                 stroke-linejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                <circle cx="12" cy="12" r="4"/>
                                <path
                                    d="M3 12h1m8 -9v1m8 8h1m-9 8v1m-6.4 -15.4l.7 .7m12.1 -.7l-.7 .7m0 11.4l.7 .7m-12.1 -.7l-.7 .7"/>
                            </svg>
                        </a>
                        <div className="nav-item dropdown">
                            <a href="#" className="nav-link d-flex lh-1 text-reset p-0" data-bs-toggle="dropdown"
                               aria-label="Open user menu">
                                <span className="avatar avatar-sm"
                                      style={{backgroundImage: `url(./static/avatars/user.jpeg)`}}></span>
                                <div className="d-none d-xl-block ps-2">
                                    <div>{props.loginData.length > 0 ? props.loginData[0]?.name : "User"}</div>
                                    <div className="mt-1 small text-muted">Administrator</div>
                                </div>
                            </a>
                            <div className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                                {/*<Link to="/user-profile" className="dropdown-item">Profile</Link>*/}
                                <Link to="/logout" className="dropdown-item">Logout</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <div className="navbar-expand-md">
                <div className="collapse navbar-collapse" id="navbar-menu">
                    <div className="navbar navbar-light">
                        <div className="container-xl">
                            <ul className="navbar-nav">
                                <li className="nav-item active">
                                    <Link className="nav-link" to="/" onClick={()=>{
                                        dismissClick()
                                    }}>
                    <span className="nav-link-icon d-md-none d-lg-inline-block">
                      <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24"
                           viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round"
                           stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><polyline
                          points="5 12 3 12 12 3 21 12 19 12"/><path d="M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-7"/><path
                          d="M9 21v-6a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v6"/></svg>
                    </span>
                                        <span className="nav-link-title">
                      Home
                    </span>
                                    </Link>
                                </li>
                                <li className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle" href="#navbar-base"
                                       data-bs-toggle="dropdown"
                                       data-bs-auto-close="outside" role="button" aria-expanded="false">
                    <span className="nav-link-icon d-md-none d-lg-inline-block">
                        <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><circle cx="9" cy="7" r="4" /><path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" /><path d="M16 3.13a4 4 0 0 1 0 7.75" /><path d="M21 21v-2a4 4 0 0 0 -3 -3.85" /></svg>
                    </span>
                                        <span className="nav-link-title">
                      Farmers
                    </span>
                                    </a>
                                    <div className="dropdown-menu">
                                        <div className="dropdown-menu-columns">
                                            <div className="dropdown-menu-column">
                                                <Link className="dropdown-item" to={"/manage-farmers"} onClick={()=>{
                                                    dismissClick()
                                                }}>
                                                    Farmers Report All
                                                </Link>
                                                <Link className="dropdown-item" to={"/farmers-list-mapped"} onClick={()=>{
                                                    dismissClick()
                                                }}>
                                                    Farmers Report Mapped
                                                </Link>
                                                <Link className="dropdown-item" to={"/farmers-list-unmapped"} onClick={()=>{
                                                    dismissClick()
                                                }}>
                                                    Farmers Report Not Mapped
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle" href="#navbar-base"
                                       data-bs-toggle="dropdown"
                                       data-bs-auto-close="outside" role="button" aria-expanded="false">
                    <span className="nav-link-icon d-md-none d-lg-inline-block">
                      <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24"
                           viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round"
                           stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><polyline
                          points="9 11 12 14 20 6"/><path
                          d="M20 12v6a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h9"/></svg>
                    </span>
                                        <span className="nav-link-title">
                      Agent
                    </span>
                                    </a>
                                    <div className="dropdown-menu">
                                        <div className="dropdown-menu-columns">
                                            <div className="dropdown-menu-column">
                                                <Link className="dropdown-item" to={"/manage-agent"} onClick={()=>{
                                                    dismissClick()
                                                }}>
                                                    Manage Agent
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle" href="#navbar-base"
                                       data-bs-toggle="dropdown"
                                       data-bs-auto-close="outside" role="button" aria-expanded="false">
                    <span className="nav-link-icon d-md-none d-lg-inline-block">
                                              <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24"
                                                   height="24" viewBox="0 0 24 24" stroke-width="2"
                                                   stroke="currentColor" fill="none" stroke-linecap="round"
                                                   stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z"
                                                                                 fill="none"/><circle cx="12" cy="12"
                                                                                                      r="9"/><circle
                                                  cx="12" cy="10" r="3"/><path
                                                  d="M6.168 18.849a4 4 0 0 1 3.832 -2.849h4a4 4 0 0 1 3.834 2.855"/></svg>

                    </span>
                                        <span className="nav-link-title">
                      Administrator
                    </span>
                                    </a>
                                    <div className="dropdown-menu">
                                        <div className="dropdown-menu-columns">
                                            <div className="dropdown-menu-column">
                                                <Link className="dropdown-item" to={"/manage-users"} onClick={()=>{
                                                    dismissClick()
                                                }}>
                                                    Manage Users
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                            <div className="my-2 my-md-0 flex-grow-1 flex-md-grow-0 order-first order-md-last">
                                <form action="." method="get">
                                    <div className="input-icon">
                    <span className="input-icon-addon">
                      <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24"
                           viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round"
                           stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><circle cx="10"
                                                                                                              cy="10"
                                                                                                              r="7"/><line
                          x1="21" y1="21" x2="15" y2="15"/></svg>
                    </span>
                                        <input type="text" className="form-control" placeholder="Search…"
                                               aria-label="Search in website"/>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        loginData: state.loginDetails,
    };
};
export default connect(mapStateToProps, null)(Header);
