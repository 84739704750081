import React, {useState, useRef, useCallback, useEffect} from "react";

import {Link} from "react-router-dom";
import {connect} from "react-redux";
import { LoadScript, GoogleMap, Polygon } from "@react-google-maps/api";
import "./style.css";
import DataLoader from "../common/dataLoader/dataLoader";
// This example presents a way to handle editing a Polygon
// The objective is to get the new path on every editing event :
// - on dragging the whole Polygon
// - on moving one of the existing points (vertex)
// - on adding a new point by dragging an edge point (midway between two vertices)
//
// We achieve it by defining refs for the google maps API Polygon instances and listeners with `useRef`
// Then we bind those refs to the currents instances with the help of `onLoad`
// Then we get the new path value with the `onEdit` `useCallback` and pass it to `setPath`
// Finally we clean up the refs with `onUnmount`

function FarmLandPreview(props) {

   //const newData =  [LatLng(9.0088292, 7.4153226), LatLng(9.013882, 7.4186849), LatLng(9.0138787, 7.4186769), LatLng(9.0138787, 7.4186769), LatLng(9.0088292, 7.4153226)];
    const [farmer, setFarmer] = useState(props.farmerDetails)
    const [coordinates, setCoordinates] = useState([])
    const [current, setCurrent] = useState([])
    const [IsLoading, setIsLoading] = useState(true)


    let resData = [];
    let resData2 = [];
    let result4 = [];
    let result5 = [];
    let mapData = [];
// Store Polygon path in state
    const [path, setPath] = useState(mapData);
    const setParamiters = () =>{
        let convertToArray =  farmer.land_points.replace(/LatLng|[()]|[\]}[{]/gi, "").split(",")
        let currLocation =  farmer.locations.replace(/LatLng|[()]|[\]}[{]/gi, "").split(",")
        resData = convertToArray
        resData2 = currLocation

        resData.map((e, i)=>{
            if (i % 2 == 0){
                result4.push(e)
            }else{
                result5.push(e)
            }
        })

        for (let i = 0; i < result4.length; i++){
            mapData.push({ lat: parseFloat(result4[i]), lng: parseFloat(result5[i]) },)
        }

        setPath(mapData)
        setCurrent(resData2)
        setIsLoading(false)
    }

    useEffect(()=>{
        setParamiters()
    }, [""])

    // Define refs for Polygon instance and listeners
    const polygonRef = useRef(null);
    const listenersRef = useRef([]);

    // Call setPath with new edited path
    const onEdit = useCallback(() => {
        if (polygonRef.current) {
            const nextPath = polygonRef.current
                .getPath()
                .getArray()
                .map(latLng => {
                    return { lat: latLng.lat(), lng: latLng.lng() };
                });
            setPath(nextPath);
        }
    }, [setPath]);

    // Bind refs to current Polygon and listeners
    const onLoad = useCallback(
        polygon => {
            polygonRef.current = polygon;
            const path = polygon.getPath();
            listenersRef.current.push(
                path.addListener("set_at", onEdit),
                path.addListener("insert_at", onEdit),
                path.addListener("remove_at", onEdit)
            );
        },
        [onEdit]
    );

    // Clean up refs
    const onUnmount = useCallback(() => {
        listenersRef.current.forEach(lis => lis.remove());
        polygonRef.current = null;
    }, [""]);

    // console.log("The path state is", path);




    return (
        IsLoading ?
            <div className="container-xl" style={{height: '700px'}}>
                <div className="page-header d-print-none">
                    <DataLoader/>
                </div>
            </div>
            :
        <>
            <div className="container" style={{height: '400px', width: '100%'}}>
                <LoadScript
                    id="script-loader"
                    googleMapsApiKey="AIzaSyAPrt-VRZuaUMhSj7HcmXEb0MWj33pukC8"
                    language="en"
                    region="ng"
                >
                    <GoogleMap
                        mapContainerClassName="App-map"
                        center={{ lat: parseFloat(current[0]), lng: parseFloat(current[1]) }}
                        zoom={18}
                        version="weekly"
                        mapTypeId="satellite"
                        id="Adam Musa Yau"
                        on
                    >
                        <Polygon
                            // Make the Polygon editable / draggable
                            // editable
                            // draggable
                            path={path}
                            // Event used when manipulating and adding points
                            onMouseUp={onEdit}
                            // Event used when dragging the whole Polygon
                            // onDragEnd={onEdit}
                            // onLoad={onLoad}
                            onUnmount={onUnmount}
                        />
                    </GoogleMap>
                </LoadScript>
            </div>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        loginData: state.loginDetails,
        farmerDetails: state.generalDetails,
    };
};
export default connect(mapStateToProps, null)(FarmLandPreview);
