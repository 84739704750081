import {projectCode} from "./constants";

const CryptoJS = require("crypto-js");

export const status = "Live";
export const serverLink =
    status === "Dev"
        ?
        "http://localhost:4480/"
        //"http://192.168.1.76:4480/"
        : "https://teamcoded.com.ng/farmer-api/";


export function encryptData(string, val = false) {
    let secret_key = val === false ? 'TEAMCODED' : projectCode;
    let secret_iv = val === false ? 'TEAMCODED' : projectCode;
    // hash
    let kee = CryptoJS.SHA256(secret_key);
    let ivv = CryptoJS.SHA256(secret_iv).toString().substr(0, 16);

    kee = CryptoJS.enc.Utf8.parse(kee.toString().substr(0, 32));
    ivv = CryptoJS.enc.Utf8.parse(ivv);

    let decrypted = CryptoJS.AES.encrypt(string, kee, { iv: ivv });

    let result = decrypted.toString();
    return btoa(result);
}

export function decryptData(string, val = false) {
    let secret_key = val === false ? 'TEAMCODED' : projectCode;
    let secret_iv = val === false ? 'TEAMCODED' : projectCode;
    // hash
    let kee = CryptoJS.SHA256(secret_key);
    let ivv = CryptoJS.SHA256(secret_iv).toString().substr(0, 16);

    kee = CryptoJS.enc.Utf8.parse(kee.toString().substr(0, 32));
    ivv = CryptoJS.enc.Utf8.parse(ivv);

    var decrypted = CryptoJS.AES.decrypt(atob(string), kee, { iv: ivv });

    var result = decrypted.toString(CryptoJS.enc.Utf8);
    return result;
}