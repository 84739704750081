import React, {useState, useEffect} from "react";
import {Link} from "react-router-dom";
import {connect} from "react-redux";

import axios from "axios";
import {decryptData, encryptData, serverLink} from "../../resources/url";
import {projectLogo} from "../../resources/constants";
import {setLoginDetails, setPermissionDetails} from "../../actions/actions";
import {showAlert} from "../common/sweetalert/sweetalert";
import {toast} from "react-toastify";

function Login(props) {

    const [IsFormLoading, setIsFormLoading] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
    const [locationData, setLocationData] = useState({})
    const [formData, setFormData] = useState({
        username: "",
        password: "",
        userIP: "",
        userCountry: "",
        lat: "",
        lng: "",
    })

   const getLocationData = async () => {
        const res = await axios.get('https://geolocation-db.com/json/')
       setLocationData(res.data)
    }

    const onEdit = (e) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value,
        });
    }

    const onLoginClicked = async () => {
        let sendData = {
            ...formData,
            password: encryptData(formData.password),
            userIP: locationData.IPv4,
            userCountry: locationData.country_name,
            lat: locationData.latitude,
            lng: locationData.longitude,
        };
        if (formData.username.trim() === "") {
            showAlert("EMPTY FIELD", "Please enter your email address", "error");
            return false;
        }
        if (formData.password.trim() === "") {
            showAlert("EMPTY FIELD", "Please enter your password", "error");
            return false;
        }


            setIsFormLoading(true);

        await axios.post(`${serverLink}login/user`, sendData)
            .then(data => {
                const response = data.data.message;
                const userData = data.data.userData;
                setIsFormLoading(false);
                if(response === "invalid_login") {
                    showAlert("Oops!", "Invalid login credential, please try again.", "error")
                    return false;
                } else if (response === "error") {
                    showAlert("Oops!", "Something went wrong. Please try again!", "error")
                    return false;
                } else if (response === "success"){
                    window.sessionStorage.setItem("userPermission", 'allow');
                    window.sessionStorage.setItem("isLoggedIn", true);
                    props.setOnLoginDetails(userData);
                    toast.success("Login Successful");
                    return false;
                } else {
                    showAlert(
                        "ERROR",
                        "Something went wrong. Please try again!",
                        "error"
                    );
                    return false;
                }

            }).catch(err => {
                showAlert(
                    "NETWORK ERROR",
                    "Please check your connection and try again!",
                    "error"
                );
            });
        }



    useEffect(()=>{
        getLocationData();
    }, [""])

    return (
        <>
            <div className="border-top-wide border-primary d-flex flex-column">
                <div className="page page-center">
                    <div className="container-tight py-4">
                        <div className="text-center mb-4">
                            <a href="." className="navbar-brand navbar-brand-autodark">
                                <img src="./static/logo.png" height="100" alt=""/>

                            </a>
                            <h2>Farmers Enumeration</h2>
                        </div>
                        <form className="card card-md">
                            <div className="card-body">
                                <h2 className="card-title text-center mb-4">Login to your account</h2>
                                <div className="mb-3">
                                    <label className="form-label">Email address</label>
                                    <input
                                        type="email"
                                        className="form-control"
                                        name="username"
                                        id="username"
                                        value={formData.username}
                                        onChange={onEdit}
                                        placeholder="Email Address"
                                    />
                                </div>
                                <div className="mb-2">
                                    <label className="form-label">
                                        Password
                                    </label>
                                    <div className="input-group input-group-flat">
                                        <input
                                            type={showPassword ? "text" : "password"}
                                            className="form-control"
                                            name="password"
                                            id="password"
                                            value={formData.password}
                                            onChange={onEdit}
                                            placeholder="Password"
                                            autoComplete="off"/>
                                        <span className="input-group-text">
                                          <a href="#" onClick={() => setShowPassword(!showPassword)}
                                             className="input-group-link">
                                              {
                                                  showPassword ?
                                                      <svg xmlns="http://www.w3.org/2000/svg" className="icon"
                                                           width="24" height="24" viewBox="0 0 24 24" stroke-width="2"
                                                           stroke="currentColor" fill="none" stroke-linecap="round"
                                                           stroke-linejoin="round">
                                                          <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                          <line x1="3" y1="3" x2="21" y2="21"/>
                                                          <path d="M10.584 10.587a2 2 0 0 0 2.828 2.83"/>
                                                          <path
                                                              d="M9.363 5.365a9.466 9.466 0 0 1 2.637 -.365c4 0 7.333 2.333 10 7c-.778 1.361 -1.612 2.524 -2.503 3.488m-2.14 1.861c-1.631 1.1 -3.415 1.651 -5.357 1.651c-4 0 -7.333 -2.333 -10 -7c1.369 -2.395 2.913 -4.175 4.632 -5.341"/>
                                                      </svg>
                                                      :
                                                      <svg xmlns="http://www.w3.org/2000/svg" className="icon"
                                                           width="24" height="24" viewBox="0 0 24 24" stroke-width="2"
                                                           stroke="currentColor" fill="none" stroke-linecap="round"
                                                           stroke-linejoin="round">
                                                          <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                          <circle cx="12" cy="12" r="2"/>
                                                          <path
                                                              d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7"/>
                                                      </svg>

                                              }
                                          </a>
                                        </span>
                                    </div>
                                </div>
                                <div className="mb-2">
                                    <label className="form-check">
                                        <input type="checkbox" className="form-check-input"/>
                                        <span className="form-check-label">Remember me on this device</span>
                                    </label>
                                </div>
                                <div className="form-footer">
                                    <button type="button" onClick={onLoginClicked} className="btn btn-primary ms-auto" >
                                        {
                                            IsFormLoading ?
                                                <span><span className="spinner-border spinner-border-sm me-2" role="status"/> Please wait...</span>
                                                :
                                                <span>
                                                    <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><rect x="4" y="4" width="16" height="16" rx="2" /><path d="M9 12l2 2l4 -4" /></svg>                                            Sign in
                                        </span>
                                        }

                                    </button>
                                </div>
                            </div>

                        </form>
                        {/*<div className="text-center text-muted mt-3">*/}
                        {/*    Don't have account yet? <a href="./sign-up.html" tabIndex="-1">Sign up</a>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        loginData: state.loginDetails,
    };
};

const mapDisptachToProps = (dispatch) => {
    return {
        setOnLoginDetails: (p) => {
            dispatch(setLoginDetails(p));
        },
        setOnPermissionDetails: (p) => {
            dispatch(setPermissionDetails(p));
        },
    };
};


export default connect(mapStateToProps, mapDisptachToProps)(Login);
