export const generalDetailsReducer = (state = [], action) => {
    switch (action.type) {
        case "SET_GENERALDETAILS_DETAILS":
            return action.payload;

        default:
            return state;
    }
};

export const permissionDetailsReducer = (state = [], action) => {
    switch (action.type) {
        case "SET_PERMISSION_DETAILS":
            return action.payload;

        default:
            return state;
    }
};

export const loginDetailsReducer = (state = [], action) => {
    switch (action.type) {
        case "SET_LOGIN_DETAILS":
            return action.payload;

        default:
            return state;
    }
};

export const farmers_data_reducer = (state = [], action) => {
    switch (action.type) {
        case "SET_FARMERS_DATA":
            return action.payload;

        default:
            return state;
    }
};

export const farmer_info_reducer = (state = [], action) => {
    switch (action.type) {
        case "SET_FARMER_INFO":
            return action.payload;

        default:
            return state;
    }
};

export const agent_data_reducer = (state = [], action) => {
    switch (action.type) {
        case "SET_AGENT_DATA":
            return action.payload;

        default:
            return state;
    }
};