import React from "react";
import { Route, Routes } from "react-router-dom";
import Dashboard from "../dashboard/dashboard";
import Header from "../common/header/header";
import Footer from "../common/footer/footer";
import ManageAgent from "../agent/manage-agent";
import ManageFarmers from "../farmers/manage-farmers";
import FarmLandPreview from "../farmers/farm-land-preview";
import FarmerDetails from "../farmers/farmer-details";
import FarmersListMapped from "../farmers/farmers-list-mapped";
import FarmersListNotMapped from "../farmers/farmers-list-not-mapped";
import Logout from "../authentication/logout";
import PageNotFound from "../404/page-not-found";
import ManageUsers from "../administrator/manage-users";

export default function PageRoutes() {
    return (<>
        <div className="wrapper">
            <Header/>
            <div className="page-wrapper">
                <Routes>
                    {/* Dashboard Redirect */}
                    <Route exact path="/" element={<Dashboard />} />
                    <Route path="/manage-agent" element={<ManageAgent/>} />
                    <Route path="/manage-users" element={<ManageUsers/>} />
                    <Route path="/manage-farmers" element={<ManageFarmers/>} />
                    <Route path="/farmers-list-mapped" element={<FarmersListMapped/>} />
                    <Route path="/farmers-list-unmapped" element={<FarmersListNotMapped/>} />
                    <Route path="/farmer-details" element={<FarmerDetails/>} />
                    <Route path="/farm-preview" element={<FarmLandPreview/>} />
                    <Route path="/logout" element={<Logout/>} />

                    <Route path="*" element={<PageNotFound />} />
                </Routes>
                <Footer/>
            </div>
        </div>
    </>)
}