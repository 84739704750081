import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import {showAlert} from "../common/sweetalert/sweetalert";
import {serverLink} from "../../resources/url";
import {toast} from "react-toastify";
import ReportTable from "../common/table/report_table";
import {BankList, formatDate} from "../../resources/constants";
import Loader from "../common/loader/loader";
import DataLoader from "../common/dataLoader/dataLoader";

function ManageAgent(props) {
    const [IsLoading, setIsLoading] = useState(true)
    const [IsFormLoading, setIsFormLoading] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
    const [agentList, setAgentList] = useState([])
    const columns = ["S/N", "Agent Name", "Gender", "Phone", "Email", "Bank", "Account No.", "Status", "Action"];
    const [data, setdata] = useState([])
    const [formData, setFormData] = useState({
        agent_id: "",
        agent_fname: "",
        agent_sname: "",
        agent_mname: "",
        agent_email: "",
        agent_phone: "",
        agent_gender: "",
        agent_dob: "",
        agent_bvn: "",
        bank_name: "",
        account_no: "",
        password: "",
        cPassword: "",
        agent_status: "1",
    })

    useEffect( () => {
         getAgentData();
    }, [""]);

    const getAgentData = async () => {
        await axios.get(`${serverLink}agent/list`)
            .then((result) => {
                if (result.data.length > 0) {
                    let rows = [];
                    result.data.map((x, i) => {
                        rows.push([
                            i + 1,
                            x.agent_fname +" "+ x.agent_sname +" "+ x.agent_mname,
                            x.agent_gender,
                            x.agent_phone,
                            x.agent_email,
                            x.bank_name,
                            x.account_no,
                            x.agent_status.toString() ==="1" ? "Active" : "Not Active",
                            <a href="#" className={"btn btn-primary btn-sm "} data-bs-toggle="modal" data-bs-target="#modal-report"
                                  onClick={() => {
                                      setFormData({
                                          ...formData,
                                          agent_id: x.agent_id,
                                          agent_fname: x.agent_fname,
                                          agent_sname: x.agent_sname,
                                          agent_mname: x.agent_mname,
                                          agent_email: x.agent_email,
                                          agent_phone: x.agent_phone,
                                          agent_gender: x.agent_gender,
                                          agent_dob: x.agent_dob,
                                          agent_bvn: x.agent_bvn,
                                          bank_name: x.bank_name,
                                          account_no: x.account_no,
                                          password: x.password,
                                          cPassword: x.cPassword,
                                          agent_status: x.agent_status,
                                      });
                                  }}>
                                Edit
                            </a>
                        ])
                    })
                    setdata(rows)
                    setAgentList(result.data);
                }
                setIsLoading(false)
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    }

    const onEdit = (e) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value,
        });
    }

    const onSubmit = async () => {
        if (formData.agent_fname.trim() === "") {
            showAlert("EMPTY FIELD", "Please enter agent first name", "error");
            return false;
        }
        if (formData.agent_sname.trim() === "") {
            showAlert("EMPTY FIELD", "Please enter agent surname", "error");
            return false;
        }
        if (formData.agent_phone.trim() === "") {
            showAlert("EMPTY FIELD", "Please enter agent phone number", "error");
            return false;
        }
        if (formData.agent_email === "") {
            showAlert("EMPTY FIELD", "Please enter agent email address", "error");
            return false;
        }
        if (formData.agent_gender === "") {
            showAlert("EMPTY FIELD", "Please enter agent gender", "error");
            return false;
        }
        if (formData.agent_dob === "") {
            showAlert("EMPTY FIELD", "Please enter agent date of birth", "error");
            return false;
        }
        if (formData.password.trim() === "") {
            showAlert("EMPTY FIELD", "Please enter password", "error");
            return false;
        }


      if (formData.agent_id === "") {
          setIsFormLoading(true);
          await axios
              .post(`${serverLink}agent/add`, formData)
              .then((result) => {
                  if (result.data.message === "success") {
                      toast.success("Agent Added Successfully");
                      setIsFormLoading(false);
                      getAgentData();
                      document.getElementById("closeModal").click();
                      setFormData({
                          ...formData,
                          agent_id: "",
                          agent_fname: "",
                          agent_sname: "",
                          agent_mname: "",
                          agent_email: "",
                          agent_phone: "",
                          agent_gender: "",
                          agent_dob: "",
                          agent_bvn: "",
                          bank_name: "",
                          account_no: "",
                          password: "",
                          cPassword: "",
                          agent_status: "1",
                      });
                  } else if (result.data.message === "exist") {
                      setIsFormLoading(false);
                      showAlert("AGENT EXIST", "Agent already exist!", "error");
                  } else {
                      setIsFormLoading(false);
                      showAlert(
                          "ERROR",
                          "Something went wrong. Please try again!",
                          "error"
                      );
                  }
              })
              .catch((error) => {
                  setIsFormLoading(false);
                  showAlert(
                      "NETWORK ERROR",
                      "Please check your connection and try again!",
                      "error"
                  );
              });
      }else{
          setIsFormLoading(true);
          await axios
              .patch(`${serverLink}agent/update`, formData)
              .then((result) => {
                  if (result.data.message === "success") {
                      toast.success("Agent Updated Successfully");
                      setIsFormLoading(false);
                      getAgentData();
                      document.getElementById("closeModal").click();
                      setFormData({
                          ...formData,
                          agent_id: "",
                          agent_fname: "",
                          agent_sname: "",
                          agent_mname: "",
                          agent_email: "",
                          agent_phone: "",
                          agent_gender: "",
                          agent_dob: "",
                          agent_bvn: "",
                          bank_name: "",
                          account_no: "",
                          password: "",
                          cPassword: "",
                          agent_status: "1",
                      });
                  } else if (result.data.message === "exist") {
                      showAlert("AGENT EXIST", "Agent already exist!", "error");
                  } else {
                      showAlert(
                          "ERROR",
                          "Something went wrong. Please try again!",
                          "error"
                      );
                  }
              })
              .catch((error) => {
                  showAlert(
                      "NETWORK ERROR",
                      "Please check your connection and try again!",
                      "error"
                  );
              });
      }


    }

    return (
        IsLoading ?
            <div className="container-xl" style={{height: '700px'}}>
                <div className="page-header d-print-none">
                    <DataLoader/>
                </div>
            </div>
            :
        <>
            <div className="container-xl">
                <div className="page-header d-print-none">
                    <div className="row align-items-center">
                        <div className="col">
                            <div className="page-pretitle">
                                {/*Agent*/}
                            </div>
                            <h2 className="page-title">
                                Manage Agent
                            </h2>
                        </div>
                        <div className="col-auto ms-auto d-print-none">
                            <div className="btn-list">

                   <a href="#" className="btn btn-primary d-none d-sm-inline-block"  data-bs-toggle="modal" data-bs-target="#modal-report"
                   onClick={()=>{
                       setFormData({
                           ...formData,
                           agent_id: "",
                           agent_fname: "",
                           agent_sname: "",
                           agent_mname: "",
                           agent_email: "",
                           agent_phone: "",
                           agent_gender: "",
                           agent_dob: "",
                           agent_bvn: "",
                           bank_name: "",
                           account_no: "",
                           password: "",
                           cPassword: "",
                           agent_status: "1",
                       });
                   }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24"
                                         viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                                         stroke-linecap="round" stroke-linejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                        <line x1="12" y1="5" x2="12" y2="19"/>
                                        <line x1="5" y1="12" x2="19" y2="12"/>
                                    </svg>
                                    Add Agent
                                </a>
                                <a href="#" className="btn btn-primary d-sm-none btn-icon" data-bs-toggle="modal"
                                   data-bs-target="#modal-report" aria-label="Create new report">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24"
                                         viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                                         stroke-linecap="round" stroke-linejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                        <line x1="12" y1="5" x2="12" y2="19"/>
                                        <line x1="5" y1="12" x2="19" y2="12"/>
                                    </svg>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="page-body">
                <div className="container-xl">
                    <div className="row row-deck row-cards">
                        <div className="col-lg-12">
                            <div className="row row-cards">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <h3 className="card-title">Agent(s) Report</h3>
                                            <div className="table-responsive">
                                                <ReportTable data={data} columns={columns} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal modal-blur fade" id="modal-report" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Agent Form</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="mb-3 form-group col-md-4">
                                    <label className="form-label">First Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="agent_fname"
                                        id="agent_fname"
                                        value={formData.agent_fname}
                                        onChange={onEdit}
                                        placeholder="First Name"
                                    />
                                </div>
                                <div className="mb-3 form-group col-md-4">
                                    <label className="form-label">Middle Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="agent_mname"
                                        id="agent_mname"
                                        value={formData.agent_mname}
                                        onChange={onEdit}
                                        placeholder="Middle Name"
                                    />
                                </div>
                                <div className="mb-3 form-group col-md-4">
                                    <label className="form-label">Surname</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="agent_sname"
                                        id="agent_sname"
                                        value={formData.agent_sname}
                                        onChange={onEdit}
                                        placeholder="Surname"
                                    />
                                </div>
                                <div className="mb-3 col-lg-6 form-group">
                                        <label className="form-label">Gender</label>
                                        <select
                                            className="form-select"
                                            name="agent_gender"
                                            id="agent_gender"
                                            value={formData.agent_gender}
                                            onChange={onEdit}
                                        >
                                            <option value="">Select Gender</option>
                                            <option value="Male">Male</option>
                                            <option value="Female">Female</option>
                                        </select>
                                </div>
                                <div className="mb-3 form-group col-md-6">
                                    <label className="form-label">Date of Birth</label>
                                    <input
                                        type="date"
                                        className="form-control"
                                        name="agent_dob"
                                        id="agent_dob"
                                        value={formatDate(formData.agent_dob)}
                                        onChange={onEdit}
                                        placeholder="DOB"
                                    />
                                </div>
                                <div className="mb-3 form-group col-md-6">
                                    <label className="form-label">Phone Number</label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        name="agent_phone"
                                        id="agent_phone"
                                        value={formData.agent_phone}
                                        onChange={onEdit}
                                        placeholder="Phone Number"
                                    />
                                </div>
                                <div className="mb-3 form-group col-md-6">
                                    <label className="form-label">Email Address</label>
                                    <input
                                        type="email"
                                        className="form-control"
                                        name="agent_email"
                                        id="agent_email"
                                        value={formData.agent_email}
                                        onChange={onEdit}
                                        placeholder="Email Address"
                                    />
                                </div>
                                <div>

                                </div>

                                <div className="mb-3 form-group col-md-12">
                                    <label className="form-label">Password</label>
                                    <div className="input-group input-group-flat">
                                        <input
                                            type={showPassword ? "text" : "password"}
                                            className="form-control"
                                            name="password"
                                            id="password"
                                            value={formData.password}
                                            onChange={onEdit}
                                            placeholder="Password"
                                            autoComplete="off"/>
                                        <span className="input-group-text">
                                          <a href="#" onClick={()=>setShowPassword(!showPassword)} className="input-group-link">
                                              {
                                                  showPassword ?
                                                  <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><line x1="3" y1="3" x2="21" y2="21" /><path d="M10.584 10.587a2 2 0 0 0 2.828 2.83" /><path d="M9.363 5.365a9.466 9.466 0 0 1 2.637 -.365c4 0 7.333 2.333 10 7c-.778 1.361 -1.612 2.524 -2.503 3.488m-2.14 1.861c-1.631 1.1 -3.415 1.651 -5.357 1.651c-4 0 -7.333 -2.333 -10 -7c1.369 -2.395 2.913 -4.175 4.632 -5.341" /></svg>
                                                        :
                                                      <svg xmlns="http://www.w3.org/2000/svg" className="icon"
                                                           width="24" height="24" viewBox="0 0 24 24" stroke-width="2"
                                                           stroke="currentColor" fill="none" stroke-linecap="round"
                                                           stroke-linejoin="round">
                                                          <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                          <circle cx="12" cy="12" r="2"/>
                                                          <path
                                                              d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7"/>
                                                      </svg>

                                              }
                                          </a>
                                        </span>
                                    </div>
                                </div>
                                <div className="hr-text hr-text-left">Bank Details</div>
                                <div className="mb-3 col-lg-6 form-group">
                                    <label className="form-label">Bank Name</label>
                                    <select
                                        className="form-select"
                                        name="bank_name"
                                        id="bank_name"
                                        value={formData.bank_name}
                                        onChange={onEdit}
                                    >
                                        <option value="">Select Bank</option>
                                        {
                                            BankList.length > 0 && BankList.map((e,i)=>{
                                                return (
                                                    <option key={i} value={e.name}>{e.name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                                <div className="mb-3 form-group col-md-6">
                                    <label className="form-label">Account Number</label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        name="account_no"
                                        id="account_no"
                                        value={formData.account_no}
                                        onChange={onEdit}
                                        placeholder="Account Number"
                                    />
                                </div>
                            </div>

                        </div>
                        <div className="modal-footer">
                            <a href="#" id="closeModal" className="btn btn-link link-secondary" data-bs-dismiss="modal">
                                Cancel
                            </a>
                            {/*data-bs-dismiss="modal"*/}
                            <button type="button" onClick={onSubmit} className="btn btn-primary ms-auto" >
                                {
                                    IsFormLoading ?
                                        <span><span className="spinner-border spinner-border-sm me-2" role="status"/> Please wait...</span>
                                        :
                                        <span>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24"
                                                 viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                                                 stroke-linecap="round" stroke-linejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                            <line x1="12" y1="5" x2="12" y2="19"/>
                                            <line x1="5" y1="12" x2="19" y2="12"/>
                                        </svg>
                                             Submit
                                        </span>
                                }

                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
const mapStateToProps = (state) => {
    return {
        loginData: state.loginDetails,
    };
};
export default connect(mapStateToProps, null)(ManageAgent);
